<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="康复影像"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <div  v-if="!user_empty" style="margin: 16px;">
            <Button round block type="info"  @click="toUploadFile" >上传图片/视频</Button>
        </div>
        <vtabs v-model="activeTab" v-if="!user_empty" type="card">
            <vtab title="图片">
                <div v-if="size_img >=0">
                    <vrow v-for="index in size_img" :key="index" gutter="20" type="flex" justify="center" style="margin-top: 15px">
                        <vcol span="12">
                            <div align="center">
                                <div v-if="(index-1)*2 <total_img">
                                    <vimg
                                            width="100"
                                            fit="cover"
                                            :src="images[(index-1)*2].image_path"
                                            @click="preImg(images[(index-1)*2].image_path)"
                                    />
                                    <div class="text">{{images[(index-1)*2].fileFileName}}</div>
                                </div>
                            </div>
                        </vcol>
                        <vcol span="12">
                            <div align="center">
                                <div v-if="(index-1)*2+1 <total_img">
                                    <vimg
                                            width="100"
                                            fit="cover"
                                            :src="images[(index-1)*2+1].image_path"
                                            @click="preImg(images[(index-1)*2+1].image_path)"

                                    />
                                    <div class="text">{{images[(index-1)*2+1].fileFileName}}</div>
                                </div>
                            </div>
                        </vcol>
                    </vrow>
                </div>

                <empty v-if="nodata_img" description="查无数据" />

            </vtab>
            <vtab title="视频">
                <div v-if="size_video >=0">
                    <vrow v-for="index of size_video" :key="index" gutter="20" type="flex" justify="center" style="margin-top: 15px">
                        <vcol span="12">
                            <div align="center">
                                <div v-if="(index-1)*2 < total_video">
                                    <vimg
                                            width="100"
                                            fit="cover"
                                            :src="videos[(index-1)*2].imageUrl"
                                            @click="toVideoInfo(videos[(index-1)*2])"
                                            @error="errLoadVideo((index-1)*2)"
                                    />
                                    <div class="text">{{videos[(index-1)*2].remark}}</div>
                                </div>

                            </div>
                        </vcol>
                        <vcol span="12">
                            <div align="center">
                                <div v-if="(index-1)*2+1 < total_video">
                                    <vimg
                                            width="100"
                                            fit="cover"
                                            :src="videos[(index-1)*2+1].imageUrl"
                                            @click="toVideoInfo(videos[(index-1)*2+1])"
                                            @error="errLoadVideo((index-1)*2+1)"
                                    />
                                    <div class="text">{{videos[(index-1)*2+1].remark}}</div>
                                </div>
                            </div>
                        </vcol>
                    </vrow>
                </div>

                <empty v-if="nodata_video" description="查无数据" />

            </vtab>
        </vtabs>
        <Loading type="spinner" color="#1989fa" size="24px" vertical v-if="user_empty" style="margin-top: 40%">获取授权中...</Loading>
        <RLFooter/>
    </div>
</template>

<script>

    import {Empty,Button,Tab as vtab, Tabs as vtabs,Image as vimg,Col as vcol, Row as vrow,ImagePreview,Loading } from 'vant';
    // import {getFollows} from '@/api/follow';
    // import {getBaseInfo} from '@/api/patient/baseinfo';
    import {getUploadFiles}  from '@/api/upload';
    // import {setToken,removeToken} from '@/utils/auth';
    import {getUser} from '@/api/user';
    import {getToken, removeToken, setToken} from "@/utils/auth";

    export default {
        name: 'QueIdx',
        components: {
            Empty,Button,vtab, vtabs,vimg,vcol,vrow,[ImagePreview.Component.name]: ImagePreview.Component,Loading
        },
        data(){
            return {
                active:0,
                images:[],
                videos:[],
                nodata_img:true,
                nodata_video:true,
                activeTab: 0,
                user:{
                    userName:'',
                    nickName:'',
                    idCard:'',
                    phone:'',
                    gender:'1',
                    birthday:'',
                    id:'',
                    pid:''
                },
                total_img:0,
                ys_img:0,
                size_img:0,
                total_video:0,
                ys_video:0,
                size_video:0,
                user_empty:true,
                fileUrl:'https://guanjietong.rilintech.com/',

            };
        },
        computed:{

        },
        methods:{
            showQue(idx){
                this.$router.push({path: '/que/'+idx.id});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            },
            preImg(url){
                // console.log(url)
                ImagePreview([url]);
            },
            toVideoInfo(video){
                //tofollowup
                this.$router.push({path: '/videoInfo/',query:{url:video.videoUrl,rid:video.id}});
            },
            toUploadFile(){
                //tofollowup
                // this.$router.push({path: '/toUploadFile/',query:{patientid:this.user.id}});
                this.$router.push({path: '/toUploadFile/',query:{patientid:this.user.id}});
            },
            errLoadVideo(index){

                console.log(index)
                this.videos[index].image_url = require('../../img/video_default.png')
                // this.videos[index].image_url = "https://img01.yzcdn.cn/vant/cat.jpeg"

            },
            initData(){

                getUploadFiles({pid:this.user.id}).then((response) => {
                    console.log(response)
                    if(response.code==200 ){
                        this.files=response.data.rows;
                        console.log(response.data)
                        this.images = response.data.imageList
                        this.videos = response.data.videoList

                        this.total_img = response.data.imageList.length
                        this.total_video = response.data.videoList.length

                        if(this.total_img == 0){
                            this.nodata_img = true
                        }else{
                            this.nodata_img = false
                        }

                        if(this.total_video == 0){
                            this.nodata_video = true
                        }else{
                            this.nodata_video = false
                        }

                        this.size_img = Math.ceil(this.total_img/2)

                        this.size_video = Math.ceil(this.total_video/2)
                        console.log("图片共"+this.total_img+"，循环"+this.size_img+"次")
                        console.log(this.images)

                        console.log("视频共"+this.total_video+"，循环"+this.size_video+"次")
                        console.log(this.videos)

                    }
                });
            },

        }
        ,
        created() {
          // 开发环境
          if(process.env.NODE_ENV=='development'){
            getUser().then((res) => {
              console.info(res.data);

              if(res.data){
                this.$store.commit('updateOpenid',res.data.openid);
                this.$store.commit('updatePid',res.data.id);
                this.$store.commit('updateBaseInfo',res.data);
                if(res.data.name !=''){
                  this.substatus = true
                }
                this.user.id=res.data.id;
                this.user.name=res.data.name;
                this.user.birthday=res.data.birthday;
                // this.user.idCard=response.data.idCard;
                this.user.telephone=res.data.telephone;
                this.user.gender=res.data.gender+'';
                this.user.address=res.data.address;
                this.user.detail_address=res.data.detail_address;
                // this.initBaseInfo();
                this.user_empty = false

                this.initData();

              }
            });
          }else{
            //生产环境
            var query = this.$route.query;
            let codeTemp = getToken();

            if(query.code || this.getQueryString('code') || codeTemp || query.patientid){
              if(query.code || this.getQueryString('code')){
                this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
                setToken(query.code?query.code : this.getQueryString('code'));
              }
              // let query = this.$route.query;
              let patientid=query.patientid;
              console.log(patientid)
              getUser({patientid:patientid}).then((res) => {
                console.info(res.data);

                if(res.data){
                  this.$store.commit('updateOpenid',res.data.openid);
                  this.$store.commit('updatePid',res.data.id);
                  this.$store.commit('updateBaseInfo',res.data);
                  if(res.data.name !=''){
                    this.substatus = true
                  }
                  this.user.id=res.data.id;
                  this.user.name=res.data.name;
                  this.user.birthday=res.data.birthday;
                  // this.user.idCard=response.data.idCard;
                  this.user.telephone=res.data.telephone;
                  this.user.gender=res.data.gender+'';
                  this.user.address=res.data.address;
                  this.user.detail_address=res.data.detail_address;
                  // this.initBaseInfo();
                  this.user_empty = false

                  this.initData();

                }
              });
              //getBaseInfo({}).then((response) => {
              //   console.info(response);
              // });
            }else{
              removeToken();
            }
          }


            /*初始化列表*/
            // this.initidx();
            // this.initBaseInfo();
        },
    }
</script>

<style scoped>

    .is_completed{
        color: aqua;
    }
    .is_uncompleted{
        color:red;
    }

</style>
